import React from "react";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import axios from "axios";

const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 500
  },
  containerDiv: {
    display: "inline"
  },
  sourceSide: {
    float: "left"
  },
  destinationSide: {
    float: "right"
  },
  submitButton: {
    float: "right"
  },
  list: {
    width: 200,
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto"
  },
  heading: {
    "margin-left": "40%",
    "margin-top": "-45px",
    "margin-bottom": "30px"
  }
});

export class FlowComponent extends React.Component {
  constructor(props) {
    super();
    this.state = {
      environments_list: [
        { name: "Dr Smile Staging", sid: "1", value: "DRSMILE_STAGING" },
        { name: "Smava Staging", sid: "2", value: "SMAVA_STAGING" },
        { name: "Localsearch Staging", sid: "3", value: "LOCALSEARCH_STAGING" },
        { name: "K24 Staging", sid: "4", value: "K24_STAGING" },
        { name: "Illy Cafe Staging", sid: "5", value: "ILLY_CAFE_STAGING" },
        { name: "Interhyp Staging", sid: "6", value: "INTERHYP_STAGING" },
        { name: "Movinga Staging", sid: "7", value: "MOVINGA_STAGING" },
        { name: "Positec Staging", sid: "8", value: "POSITEC_STAGING" },
        { name: "Experimental 2 C20Y", sid: "9", value: "EXPERIMENTAL_2_C20Y" },
        { name: "Mymoria Staging", sid: "10", value: "MYMORIA_STAGING" },
        { name: "IU Development", sid: "11", value: "IU_DEVELOPMENT" },
        { name: "IU UAT", sid: "12", value: "IU_UAT" },
        { name: "Flex Template 2.0", sid: "13", value: "FLEX_TEMPLATE_2.0" },
        { name: "Wattfox Dev", sid: "14", value: "WATTFOX_DEV" },
        { name: "Fairfax Dev", sid: "15", value: "FAIRFAX_DEV" },
        { name: "Localsearch Production", sid: "16", value: "LOCALSEARCH_PRODUCTION" },
        { name: "Localsearch Dev v2", sid: "17", value: "LOCALSEARCH_STAGING_V2" },
        { name: "ThinkProject-Main", sid: "18", value: "THINKPROJECT_MAIN" },
        { name: "ThinkProject-Stage", sid: "19", value: "THINKPROJECT_STAGE" },
        { name: "ThinkProject-Prod", sid: "20", value: "THINKPROJECT_PROD" },
        { name: "DTA Dev", sid: "21", value: "DTA_DEV" },
        { name: "DTA Prod", sid: "22", value: "DTA_PROD" }
      ],
      destination_list: [
        { name: "Dr Smile Production", sid: "1", value: "DRSMILE_PRODUCTION" },
        { name: "Smava Staging", sid: "2", value: "SMAVA_STAGING" },
        { name: "Localsearch Production", sid: "3", value: "LOCALSEARCH_PRODUCTION" },
        { name: "K24 Production", sid: "4", value: "K24_PRODUCTION" },
        { name: "Illy Cafe Staging", sid: "5", value: "ILLY_CAFE_STAGING" },
        { name: "Interhyp Staging", sid: "6", value: "INTERHYP_STAGING" },
        { name: "Movinga Production", sid: "7", value: "MOVINGA_PRODUCTION" },
        { name: "Positec Production", sid: "8", value: "POSITEC_PRODUCTION" },
        { name: "Experimental 2 C20Y", sid: "9", value: "EXPERIMENTAL_2_C20Y" },
        { name: "Mymoria Production", sid: "10", value: "MYMORIA_PRODUCTION" },
        { name: "IU UAT", sid: "11", value: "IU_UAT" },
        { name: "IU Production", sid: "12", value: "IU_PRODUCTION" },
        { name: "SC Ukraine Flex", sid: "13", value: "SC_UKRAINE_FLEX" },
        { name: "Wattfox Prod", sid: "14", value: "WATTFOX_PROD" },
        { name: "Fairfax Prod", sid: "15", value: "FAIRFAX_PROD" },
        { name: "Localsearch Prod v2", sid: "16", value: "LOCALSEARCH_PRODUCTION_V2" },
        { name: "ThinkProject-Main", sid: "18", value: "THINKPROJECT_MAIN" },
        { name: "ThinkProject-Stage", sid: "19", value: "THINKPROJECT_STAGE" },
        { name: "ThinkProject-Prod", sid: "20", value: "THINKPROJECT_PROD" },
        { name: "DTA Dev", sid: "21", value: "DTA_DEV" },
        { name: "DTA Prod", sid: "22", value: "DTA_PROD" }
      ],
      source: {},
      destination: {},
      checked: [],
      sourceNames: [],
      sourceItems: {},
      destItems: [],
      open: false
    };
  }

  sourceSelected = async (event) => {
    let val = event.target.value;
    console.log("source", val);
    let flows = await this.listFlows(val);
    let flowNames = Object.keys(flows);
    this.setState({
      source: event.target.value,
      sourceItems: flows,
      sourceNames: flowNames,
      checked: []
    });
  };

  destinationSelected = async (event) => {
    let val = event.target.value;
    let flows = await this.listFlows(val);
    let flowNames = Object.keys(flows);
    this.setState({
      destination: event.target.value
    });
  };

  listFlows = async (key) => {
    let resp = await axios({
      method: "get",
      url: "/flows/list/" + encodeURIComponent(key)
    });
    let list = resp.data.flows;
    return list;
  };

  handleToggle = (item) => {
    let newState = [...this.state.checked];
    let index = newState.indexOf(item);
    console.log(">>>>index", index);
    if (index > -1) {
      newState.splice(index, 1);
    } else {
      newState.push(item);
    }
    this.setState({
      checked: newState
    });
  };

  submitForm = async () => {
    let resp = await axios({
      method: "post",
      url: "/flows/copy",
      data: {
        sourceKey: this.state.source,
        destinationKey: this.state.destination,
        flows: this.state.checked
      },
      headers: { "Content-Type": "Application/json" }
    });
    resp;
  };

  showResponse = () => {
    this.setState({ open: true });
  };

  hideResponse = () => {
    this.setState({ open: false });
  };

  transferResponsePopup = (resp) => {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          {/* <DialogTitle id="alert-dialog-title">
                {"Successful Alert"}
              </DialogTitle> */}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Failed Mappings</DialogContentText>
            <DialogContentText id="alert-dialog-description">Functions: {resp.run_functions}</DialogContentText>
            <DialogContentText id="alert-dialog-description">Send To flex: {resp.send_to_flex}</DialogContentText>
            <DialogContentText id="alert-dialog-description">Say Play: {resp.say_play}</DialogContentText>
            <DialogContentText id="alert-dialog-description">Http Request Widgets: {resp.make_http_request}</DialogContentText>
          </DialogContent>
          {/* <DialogActions>
                        <Button onClick={this.handleDisagree} color="primary">
                            Disagree
                        </Button>
                        <Button onClick={this.handleAgree} color="primary" autoFocus>
                            Agree
                        </Button>
                    </DialogActions> */}
        </Dialog>
      </div>
    );
  };

  render() {
    let classes = this.props.classes;
    let _this = this;
    const envs_list_staging = this.state.environments_list.map((env) => {
      return (
        <option key={env.value} value={env.value}>
          {env.name}
        </option>
      );
    });
    const envs_list_prod = this.state.destination_list.map((env) => {
      return (
        <option key={env.value} value={env.value}>
          {env.name}
        </option>
      );
    });

    return (
      <div className={classes.containerDiv}>
        <div className={classes.heading}>
          <h2>Studio Flows</h2>
        </div>
        <div className={classes.sourceSide}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="grouped-native-select">Source</InputLabel>
            <Select native defaultValue="" id="grouped-native-select-source" onChange={this.sourceSelected}>
              <option aria-label="None" value="" />
              <optgroup label="Staging">{envs_list_staging}</optgroup>
              <optgroup label="Production">{envs_list_prod}</optgroup>
            </Select>
          </FormControl>
          <List className={classes.list} dense component="div" role="list">
            {Object.entries(this.state.sourceItems).map(([name, sid]) => {
              console.log(">>>>>>>> key value", name, sid);
              const labelId = `transfer-list-all-item-${name}-label`;
              return (
                <ListItem key={sid} role="listitem" button onClick={() => _this.handleToggle(sid)}>
                  <ListItemIcon>
                    <Checkbox checked={this.state.checked.indexOf(sid) !== -1} tabIndex={-1} disableRipple inputProps={{ "aria-labelledby": labelId }} />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={`${name}`} />
                </ListItem>
              );
            })}
            <ListItem />
          </List>
        </div>
        <div className={classes.destinationSide}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="grouped-native-select">Destination</InputLabel>
            <Select native defaultValue="" id="grouped-native-select-destination" onChange={this.destinationSelected}>
              <option aria-label="None" value="" />
              <optgroup label="Staging">{envs_list_staging}</optgroup>
              <optgroup label="Production">{envs_list_prod}</optgroup>
            </Select>
          </FormControl>
          <List className={classes.list} dense component="div" role="list">
            {Object.entries(this.state.destItems).map(([name, sid]) => {
              const labelId = `transfer-list-all-item-${sid}-label`;

              return (
                <ListItem key={sid} role="listitem" button onClick={() => _this.handleToggle(sid)}>
                  <ListItemIcon>
                    <Checkbox checked={this.state.checked.indexOf(sid) !== -1} tabIndex={-1} disableRipple inputProps={{ "aria-labelledby": labelId }} />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={`${name}`} />
                </ListItem>
              );
            })}
            <ListItem />
          </List>
        </div>
        <div className={classes.submitButton}>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => {
              this.submitForm(e);
            }}
          >
            {" "}
            Submit{" "}
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(FlowComponent);
