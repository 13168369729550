import React from "react";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import axios from "axios";

const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 500
  },
  containerDiv: {
    display: "inline"
  },
  sourceSide: {
    float: "left"
  },
  destinationSide: {
    float: "right"
  },
  submitButton: {
    float: "right"
  },
  list: {
    width: 200,
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto"
  },
  heading: {
    "margin-left": "40%",
    "margin-top": "-45px",
    "margin-bottom": "30px"
  },
  submitButton: {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    backgroundColor: "#005041",
    color: "#fff"
  }
});

export class ActivitiesComponent extends React.Component {
  constructor(props) {
    super();
    this.state = {
      environments_list: [
        { name: "Dr Smile Staging", sid: "1", value: "DRSMILE_STAGING" },
        { name: "Smava Staging", sid: "2", value: "SMAVA_STAGING" },
        { name: "Localsearch Staging", sid: "3", value: "LOCALSEARCH_STAGING" },
        { name: "K24 Staging", sid: "4", value: "K24_STAGING" },
        { name: "Illy Cafe Staging", sid: "5", value: "ILLY_CAFE_STAGING" },
        { name: "Interhyp Staging", sid: "6", value: "INTERHYP_STAGING" },
        { name: "Movinga Staging", sid: "7", value: "MOVINGA_STAGING" },
        { name: "Positec Staging", sid: "8", value: "POSITEC_STAGING" },
        { name: "Experimental 2 C20Y", sid: "9", value: "EXPERIMENTAL_2_C20Y" },
        { name: "Mymoria Staging", sid: "10", value: "MYMORIA_STAGING" },
        { name: "IU Development", sid: "11", value: "IU_DEVELOPMENT" },
        { name: "IU UAT", sid: "12", value: "IU_UAT" },
        { name: "Flex Template 2.0", sid: "13", value: "FLEX_TEMPLATE_2.0" },
        { name: "Wattfox Dev", sid: "14", value: "WATTFOX_DEV" },
        { name: "Fairfax Dev", sid: "15", value: "FAIRFAX_DEV" },
        { name: "Localsearch Production", sid: "16", value: "LOCALSEARCH_PRODUCTION" },
        { name: "Localsearch Dev v2", sid: "17", value: "LOCALSEARCH_STAGING_V2" },
        { name: "ThinkProject-Main", sid: "18", value: "THINKPROJECT_MAIN" },
        { name: "ThinkProject-Stage", sid: "19", value: "THINKPROJECT_STAGE" },
        { name: "ThinkProject-Prod", sid: "20", value: "THINKPROJECT_PROD" },
        { name: "DTA Dev", sid: "21", value: "DTA_DEV" },
        { name: "DTA Prod", sid: "22", value: "DTA_PROD" }
      ],
      destination_list: [
        { name: "Dr Smile Production", sid: "1", value: "DRSMILE_PRODUCTION" },
        { name: "Smava Staging", sid: "2", value: "SMAVA_STAGING" },
        { name: "Localsearch Production", sid: "3", value: "LOCALSEARCH_PRODUCTION" },
        { name: "K24 Production", sid: "4", value: "K24_PRODUCTION" },
        { name: "Illy Cafe Staging", sid: "5", value: "ILLY_CAFE_STAGING" },
        { name: "Interhyp Staging", sid: "6", value: "INTERHYP_STAGING" },
        { name: "Movinga Production", sid: "7", value: "MOVINGA_PRODUCTION" },
        { name: "Positec Production", sid: "8", value: "POSITEC_PRODUCTION" },
        { name: "Experimental 2 C20Y", sid: "9", value: "EXPERIMENTAL_2_C20Y" },
        { name: "Mymoria Production", sid: "10", value: "MYMORIA_PRODUCTION" },
        { name: "IU UAT", sid: "11", value: "IU_UAT" },
        { name: "IU Production", sid: "12", value: "IU_PRODUCTION" },
        { name: "SC Ukraine Flex", sid: "13", value: "SC_UKRAINE_FLEX" },
        { name: "Wattfox Prod", sid: "14", value: "WATTFOX_PROD" },
        { name: "Fairfax Prod", sid: "15", value: "FAIRFAX_PROD" },
        { name: "Localsearch Prod v2", sid: "16", value: "LOCALSEARCH_PRODUCTION_V2" },
        { name: "Localsearch Dev v2", sid: "17", value: "LOCALSEARCH_STAGING_V2" },
        { name: "ThinkProject-Main", sid: "18", value: "THINKPROJECT_MAIN" },
        { name: "ThinkProject-Stage", sid: "19", value: "THINKPROJECT_STAGE" },
        { name: "ThinkProject-Prod", sid: "20", value: "THINKPROJECT_PROD" },
        { name: "DTA Dev", sid: "21", value: "DTA_DEV" },
        { name: "DTA Prod", sid: "22", value: "DTA_PROD" }
      ],
      source: "",
      destination: "",
      checked: [],
      sourceItems: [],
      destItems: [],
      open: false,
      setChecked: false,
      allChecked: false
    };
  }

  sourceSelected = async (event) => {
    let val = event.target.value;
    let activities = await this.listActivities(val);
    let data = [{ sid: 1, name: "Select All", isChecked: false }];
    activities.forEach((d) => {
      d.isChecked = false;
      data.push(d);
    });
    this.setState({
      source: event.target.value,
      sourceItems: data,
      checked: []
    });
    this.setState({ sourceItems: data });
  };

  destinationSelected = async (event) => {
    let val = event.target.value;
    let activities = await this.listActivities(val);
    this.setState({
      destination: event.target.value,
      destItems: activities
    });
  };

  listActivities = async (key) => {
    let resp = await axios({
      method: "get",
      url: "/activities/list/" + encodeURIComponent(key)
    });
    let list = resp.data.activities;
    return list;
  };

  handleToggle = (item) => {
    this.setState({
      checked: [...this.state.checked, item]
    });
  };

  submitForm = async () => {
    let submitActivities = this.state.checked.filter((t) => t.name != "Select All");
    let activities = await axios({
      method: "post",
      url: "/activities/copy",
      data: {
        sourceKey: this.state.source,
        destinationKey: this.state.destination,
        activities: submitActivities
      },
      headers: { "Content-Type": "Application/json" }
    });
    null;
    console.log("activities >>>>>>>", activities);
    console.log("activities.data >>>>>>>", activities.data);
    await this.setState({
      source: "",
      destination: "",
      checked: [],
      sourceItems: [],
      destItems: [],
      open: false,
      setChecked: false,
      allChecked: false
    });
    if (activities.status == 200) {
      alert("Activities added successfully");
    } else {
      var message = activities.data ? activities.data.error : "Some error occurred";
      alert(message);
    }
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleAgree = () => {
    console.log("I agree!");
    this.submitForm();
    this.handleClose();
  };

  handleDisagree = () => {
    console.log("I do not agree.");
    this.handleClose();
  };

  handleChange = (e) => {
    //Check uncheck options
    let itemName = e.target.name;
    let itemSid = e.target.sid;
    let checked = e.target.checked;
    this.setState((prevState) => {
      let { sourceItems, allChecked } = prevState;
      if (itemName === "Select All") {
        allChecked = checked;
        sourceItems = sourceItems.map((item) => ({ ...item, isChecked: checked }));
        checked = sourceItems;
      } else {
        sourceItems = sourceItems.map((item) => (item.name === itemName ? { ...item, isChecked: checked } : item));
        checked = sourceItems.filter((item) => item.isChecked == true);
        allChecked = sourceItems.every((item) => item.isChecked);
      }
      return { sourceItems, allChecked, checked };
    });
  };

  popup() {
    let classes = this.props.classes;
    return (
      <div>
        <Button className={classes.submitButton} onClick={this.handleClickOpen}>
          Submit
        </Button>
        <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`Are you sure you want to copy the activity from ${this.state.source} to ${this.state.destination}?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDisagree} color="primary">
              Disagree
            </Button>
            <Button onClick={this.handleAgree} color="primary" autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  render() {
    let classes = this.props.classes;
    let _this = this;
    const twilio_envs_list = this.state.environments_list.map((env) => {
      return (
        <MenuItem key={env.key} value={env.key}>
          {env.name}
        </MenuItem>
      );
    });
    // twilio_envs_list.unshift(<MenuItem key={''} value={''}>{''}</MenuItem>)

    let destination_envs_list = this.state.destination_list.map((env) => {
      return (
        <MenuItem key={env.key} value={env.key}>
          {env.name}
        </MenuItem>
      );
    });
    if (this.state.source && Object.keys(this.state.source).length > 0) {
      let source_project = this.state.environments_list.filter((obj) => {
        return obj.key == this.state.source;
      });
      let destination_project = this.state.destination_list.filter((obj) => {
        return obj.sid == source_project[0].sid;
      });
      destination_envs_list = destination_project.map((env) => {
        return (
          <MenuItem key={env.key} value={env.key}>
            {env.name}
          </MenuItem>
        );
      });
    }
    console.log(twilio_envs_list);
    return (
      <div className={classes.containerDiv}>
        <div className={classes.heading}>
          <h2>ACTIVITIES</h2>
        </div>
        <div className={classes.sourceSide}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="grouped-native-select">Source</InputLabel>
            <Select defaultValue="" id="grouped-native-select-source" value={this.state.source} label="Source" onChange={this.sourceSelected}>
              {/* <option aria-label="None" value="" />
                        <optgroup label="Staging">
                            {twilio_envs_list}
                        </optgroup>
                        <optgroup label="Production">
                            
                        </optgroup> */}
              {/* <MenuItem value={this.state.source}>
                          <em>None</em>
                        </MenuItem> */}
              <ListSubheader>Staging</ListSubheader>
              {twilio_envs_list}
              <ListSubheader>Production</ListSubheader>
            </Select>
          </FormControl>
          <List className={classes.list} dense component="div" role="list">
            {this.state.sourceItems.map((item) => {
              const labelId = `transfer-list-all-item-${item.sid}-label`;

              return (
                <ListItem key={item.sid} role="listitem" button onClick={() => _this.handleToggle(item)}>
                  <ListItemIcon>
                    <Checkbox
                      key={item.sid}
                      checked={item.isChecked}
                      name={item.name}
                      sid={item.sid}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                      onChange={this.handleChange}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={`${item.name}`} />
                </ListItem>
              );
            })}
            <ListItem />
          </List>
        </div>
        <div className={classes.destinationSide}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="grouped-native-select">Destination</InputLabel>
            <Select defaultValue="" id="grouped-native-select-destination" value={this.state.destination} onChange={this.destinationSelected}>
              {/* <option aria-label="None" value="" />
                        <optgroup label="Staging"> */}
              {/* {twilio_envs_list} */}
              {/* </optgroup>
                        <optgroup label="Production">
                            {destination_envs_list}
                        </optgroup> */}
              <ListSubheader>Staging</ListSubheader>
              {twilio_envs_list}
              <ListSubheader>Production</ListSubheader>
              {destination_envs_list}
            </Select>
          </FormControl>
          <List className={classes.list} dense component="div" role="list">
            {this.state.destItems.map((item) => {
              const labelId = `transfer-list-all-item-${item.sid}-label`;

              return (
                <ListItem key={item.sid} role="listitem" button onClick={() => _this.handleToggle(item)}>
                  <ListItemIcon>
                    <Checkbox checked={this.state.checked.indexOf(item) !== -1} tabIndex={-1} disableRipple inputProps={{ "aria-labelledby": labelId }} />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={`${item.name}`} />
                </ListItem>
              );
            })}
            <ListItem />
          </List>
        </div>
        <div className={classes.submitButton}>
          {this.popup()}
          {/* <Button variant="contained" color="primary" onClick= {(e) => {this.submitForm(e)}}> Submit </Button> */}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ActivitiesComponent);
